<template>
<div id="stellaroux">
  <router-link :to="{ name: 'StellaRoux', params: { id: id } }">

    <img v-if='lang=="it"' src="../../../assets/img/stella_roux-banner.png">
    <img v-if='lang=="en"' src="../../../assets/img/stella_roux-banner-eng.png">
  </router-link>
</div>
</template>

<script>
export default {
  name: 'StellaRoux.vue',
  lang: '',

  data() {
    return {
      url: "",
      lang: '',
    };
  },
  mounted: function () {
    this.lang = this.$store.getters['auth/getLanguage'];
  },
}

</script>

<style scoped>
#stellaroux {
  background-image: url("../../../assets/backgrounds/internal.png");
  padding-bottom: 20px;
}

img {
  width: 100%;
}
</style>
