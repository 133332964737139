<template>
  <div id="footer">
    <p>
      Sirman Spa - <a href="mailto:info@sirman.com?Subject=Contatti">info@sirman.com</a> - <a href="https://www.sirman.com" target="_blank">www.sirman.com</a>
    </p>
    <!--
    <p>
      <strong>Sirman SpA C 2022</strong>
      - Viale dell’Industria, 9/11 - 35010 Curtarolo (PD)
    </p>
    <p>Tel: +39 049 9698666 - Fax: +39 049 9698688 - Email: info@sirman.com</p>
    <p>
      <router-link :to="{ name: 'Page Details', params: { id: 3 } }">
        Informativa sulla Privacy
      </router-link>
      /

      <router-link :to="{ name: 'Page Details', params: { id: 149 } }">
        Termini e condizioni
      </router-link>


      <a href=""></a>
      |
      <a href="">Dichiarazione dei Cookie</a>
    </p>
    <div class="powered">
      Powered by
      <a href="https://studioverde.it" target="_blank">Studioverde</a>
    </div>
    -->
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
#footer {
  background-color: var(--var-primary-color);
  padding: 20px;
  color: white;
  font-weight: 600;
}
#footer strong {
  font-weight: 900;
}

#footer a,
#footer a:visited {
  color: white;
}
</style>
