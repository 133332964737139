import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { createStore } from 'vuex';
import App from './App.vue';
import router from '@/router';
import api from '@/api';
import './registerServiceWorker';
import auth from './store/auth/index.js'
import { createGettext } from "vue3-gettext";
import './assets/css/custom.css';
import createPersistedState from "vuex-persistedstate";
import Embed from 'v-video-embed'
import translations from "./language/translations.json";

const myMixin = {
  methods: {
    getImage(id = 0) {
      return this.axios
        .get(this.api.getMediaUrl + id)
        .then(res => {
          return res.data.media_details.sizes;
        })
        .catch(err => {
          console.log('Errror: ' + err);
        });
    },
  },
};

const store = createStore({
  plugins: [createPersistedState()],
  modules: {
    auth: auth
  },
  state: {
    breadcrumb: [],
  },
  mutations: {
    setBreadcrumb(state, payload) {
      this.state.breadcrumb = [];
      this.state.breadcrumb.push({
        id: 0,
        label: 'Home',
        link: '/',
      });
      payload.forEach(item => {
        this.state.breadcrumb.push(item);
      });
    },
    //setUSerStatus(state, payload) {},
  },
});

const app = createApp({
  extends: App,
  mixins: [myMixin],
});

var dataProfile = store.getters['auth/getDataProfile'];
if (dataProfile.language == '') {
  dataProfile.language = 'it';
}


app.use(router);
app.use(VueAxios, axios);
app.use(store);
app.use(auth);
app.use(Embed);
app.use(createGettext({ availableLanguages: ['it','en'], defaultLanguage: dataProfile.language,
  translations }));

app.config.globalProperties.api = api;
app.config.globalProperties.$imgPlaceHolder = '/images/placeholder-768x768.png';
app.mount('#app');
