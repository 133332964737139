<template>
  <Header></Header>
  <main>
    <router-view :key="$route.fullPath"></router-view>
  </main>
  <div style="clear: both"></div>
  <Footer></Footer>
</template>

<script>
import Header from '@/components/header/Header';
import Footer from '@/components/shared/Footer';

import '../src/assets/css/custom.css';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,600;0,900;1,400;1,600;1,900');

html {
  overflow-x: hidden;
}

html,
body,
body * {
  font-family: 'Barlow', sans-serif;
}

#app {
  font-family: Barlow, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}

main {
  width: 100%;
  float: left;
  padding: 0;
  background-image: url('./assets/backgrounds/internal.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 260px);
}

.no-main-padding {
  margin-top: -20px;
}

:root {
  --var-primary-color: #e30613;
  --var-secondary-color: #343434;
  --var-padding-internal: 10px;
  --var-width-field: 80%;
}

h1,
h2,
h3,
.title {
  color: var(--var-primary-color);
}

h1 {
  font-weight: 900;
}

h2 {
  font-weight: 600;
}

h3 {
  font-weight: 400;
}

.clearfix {
  clear: both;
}

#gallery_wrap {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 90%;
}

#gallery_wrap img {
  width: 100%;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.button,
#button {
  margin-top: 10px;
}

button {
  padding: 10px;
  background-color: var(--var-secondary-color);
  color: white;
  border: 1px #ccc solid;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 20px;
}
.button:disabled {
  opacity: 0.4;
}

#button:disabled {
  opacity: 0.4;
}
button:disabled {
  opacity: 0.4;
}

.error,
.errore,
#errore {
  color: var(--var-primary-color);
  font-size: 80%;
  line-height: 150%;
}

.error a,
.errore a,
#errore a {
  color: var(--var-primary-color);
  letter-spacing: 2px;
  font-weight: 600;
  text-decoration: none;
}
</style>
