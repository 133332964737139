<template>
  <header>
    <div class="container grid">
      <div class="column-full logo-extended-container">
        <div><router-link :to="{ name: 'Home' }">
          <img src="../../assets/logo/logo-extended.svg" />
        </router-link>

        <div class="flag">
          <div class="it" style="width:30px;" @click="settaLingua('it');">
            <img src="../../assets/img/flag-it.svg" />
          </div>
          <div class="gb" @click="settaLingua('en');">
            <img src="../../assets/img/flag-gb.svg" />
          </div>
        </div>
        </div>
      </div>
      <div class="column logo-rounded-container">
        <router-link :to="{ name: 'Home' }">
          <img src="../../assets/logo/logo-rounded.svg" />
        </router-link>
      </div>
      <div class="column-full actions-btns">
        <div v-if="isLoggedIn" class="btns">
          <router-link
            v-if="isLoggedIn"
            :to="{ name: 'Profile' }"
            class="actions-btns__btn"
          >
          {{ $gettext('Profilo') }}
          </router-link>
        </div>
        <div v-if="!isLoggedIn" class="btns">
          <router-link
            v-if="!isLoggedIn"
            :to="{ name: 'Login' }"
            class="actions-btns__btn"
          >
          {{ $gettext('Sign up / Log In')}}
          </router-link>
        </div>
        <div v-if="isLoggedIn" class="btns">
          <router-link
            v-if="isLoggedIn"
            :to="{ name: 'Recipe new' }"
            class="actions-btns__btn"
          >
          {{ $gettext('Salva la tua ricetta') }}
          </router-link>
        </div>
      </div>
    </div>
  </header>

  <Menu></Menu>
</template>

<script>
import Menu from './Menu';

export default {
  name: 'Header',
  components: {
    Menu,
  },

  data() {
    return {
      show: false,
      visible: false,
    };
  },
  methods: {
    alternateShow() {
      this.visible = !this.visible;
    },
    close() {
      this.visible = false;
    },
    login() {
      this.$router.push('/login');
    },
    settaLingua(lingua) {
      console.log(this.$language);
      this.$language.current = lingua;
      this.$store.commit('auth/setLanguage', lingua);
      console.log(this.$router.currentRoute.value['name']);
      if (this.$router.currentRoute.value['name'] == 'Home') {
        this.$router.go();
      }
      else {
        this.$router.replace({
          name: 'Home',
          force: true
        });
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
  },
};
</script>

<style scoped>
header {
  padding: 20px 0;
  background-color: #f7f5f4;
}

a {
  text-decoration: none;
}

.container.grid {
  max-width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
}

.column {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.logo-extended-container img,
.logo-rounded-container img {
  width: 80px;
  height: auto;
}

.column-full {
  width: 100%;
}

.actions-btns {
  /*display: flex;*/
  align-items: center;
}

.actions-btns > div:first-child {
  /*display: flex;*/
  margin-top: 14px;
}

.actions-btns__btn:first-child {
  margin-bottom: 5px;
}

.actions-btns__btn {
  width: 100%;
  display: block;
  background-color: #3a9691;
  color: #fff;
  border-radius: 100px;
  font-size: 14px;
  padding: 5px;
}

.red {
  color: var(--var-primary-color);
  background-color: var(--var-primary-color);
}

.column {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.logo-extended-container img,
.logo-rounded-container img {
  width: 80px;
  height: auto;
}

.column-full {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.actions-btns .btns {
  width: 100%;
}

.actions-btns__btn:first-child {
  margin-bottom: 5px;
}

.actions-btns__btn {
  width: 100%;
  display: block;
  background-color: #343434;
  color: #fff;
  border-radius: 100px;
  font-size: 14px;
  padding: 5px;
}

.flag {
  margin-top: 10px;
}

.flag .it {

}
.flag .it {
  float: left;
}

.flat .gb {
  float: right;
}
.flag img {
  width: 20px;
}
</style>
