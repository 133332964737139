<template>
  <div class="menu-container">
    <div class="menu" :class="{ hide: visible == false }">
      <div v-for="link in links" :key="link.title">
        <router-link v-if="link.visible == true"
          :to="{ name: link.name, params: link.params }"
          class="menu__item"
        >
          {{ $gettext(link.title) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['visible'],
  name: 'Menu',
  data() {
    return {
      links: [
        { title: 'My Feed', name: 'MyFeed', params: {}, visible: true },
        { title: 'My Bookmark', name: 'MyBook', params: {}, visible: true },
        {
          title: 'Ricette Innovative',
          name: 'Recipe sirman',
          params: { type: 0 },
          visible: true,
        },
        {
          title: 'Le vostre ricette',
          name: 'Recipe sirman utenti',
          params: { type: 1 },
          visible: true,
        },
        {
          title: 'News dal mondo cocktail',
          name: 'News Listing',
          params: {},
          visible: true,
        },
        {
          title: 'Professione Bartender',
          name: 'Bardender',
          params: {},
          visible: true,
        },
        {
          title: 'Le nostre macchine',
          name: 'Macchine sirman',
          params: {},
          visible: true,
        },
      ],
    };
  },
  created() {
    if (!this.$store.getters['auth/isLoggedIn']) {
      this.links[0].visible = false;
    }
  },
  /*computed: {
    myFeed() {
      if (!this.$store.getters['auth/isLoggedIn']) {
        return false
      }
      return true
    }
  },
  */
  watch: {
    '$store.state.auth.token': {
      immediate: true,
      handler(value) {
        console.log("token dentro watch");
        console.log(this.$store.getters['auth/getToken']);
        console.log(this.links);
        if (!this.$store.getters['auth/getToken'] || this.$store.getters['auth/getToken'] == '') {
          this.links[0].visible = false;
          this.links[1].visible = false;
        } else {
          this.links[0].visible = true;
          this.links[1].visible = true;
        }
      },
    },
  },
};
</script>

<style scoped>
.menu-container {
  position: relative;
  display: flex;
  align-items: center;
}

.menu-container:after {
  content: '';
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(247, 245, 244, 0.42200630252100846) 0%,
    rgba(247, 245, 244, 0.42200630252100846) 17%,
    rgba(247, 245, 244, 0.8533788515406162) 41%,
    rgba(247, 245, 244, 1) 65%,
    rgba(247, 245, 244, 1) 100%
  );
}

.menu {
  display: flex;
  align-items: center;
  width: auto;
  height: 40px;
  padding: 10px 20px;
  overflow-x: scroll;
  background-color: #f7f5f4;
}

.menu::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.menu > div {
  float: left;
  display: inline;
}

.menu__item {
  display: inline-block;
  padding: 0 10px;
  font-size: 16px;
  color: #343434;
  /*font-family: 'Cabin', sans-serif;*/
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  word-break: keep-all;
  white-space: nowrap;
}
</style>
