<template>
<div id="chiusura">
  <img src="../../../assets/img/fondo-home.png">
</div>
</template>

<script>
export default {
  name: 'chiusura'
}
</script>

<style scoped>
#chiusura img {
  width: 100%;
  max-width: 100%;
}
</style>
