<template>
  <div
    class="category-card"
    v-bind:style="{
      'background-image': 'url(\'' + image + '\')',
      'color': 'green',
    }"
  >
    <h3 class="category-card__category">{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: 'Category',
  props: ['image', 'title', 'id'],
};
</script>

<style scoped>
.category-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 9999px;
  overflow: hidden;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.category-card__category {
  position: absolute;
  margin: 0;
  color: white;
  text-transform: uppercase;
  transition: 0.5s;
  z-index: 9999;
}

.category-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  border-radius: 16px;
  overflow: hidden;
  opacity: 0;
}

@media all and (max-width: 768px) {
  h3 {
    font-size: 14px !important;
    font-weight: 900;
    color: #e3e3e3;
  }
}
</style>
