<template>
  <div class="home container grid">
    <categories-section></categories-section>
    <news-section></news-section>
    <bartender-section></bartender-section>
    <!--<button @click="reload">Reload app</button>-->
  </div>
  <stella-roux></stella-roux>
  <chiusura></chiusura>
</template>

<script>
import CategoriesSection from './categories-section/CategoriesSection';
import NewsSection from './news-section/NewsSection';
import BartenderSection from './bartender-section/BartenderSection';
import StellaRoux from './stellaroux-section/StellaRoux';
import Chiusura from './chiusura-section/chiusura';

export default {
  name: 'Home',
  props: {
    msg: String,
  },
  components: {
    BartenderSection,
    CategoriesSection,
    NewsSection,
    StellaRoux,
    Chiusura,
  },

  created() {},

  methods: {
    reload() {
      window.location.reload(true);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  background-image: url('../../assets/backgrounds/internal.png');
  background-position: center;
  background-size: 140%;
  background-repeat: no-repeat;
}
</style>
