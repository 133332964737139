<template>
  <div v-if="categories" class="categories">
    <h2> {{ $gettext("I nostri cocktail innovativi") }}</h2>

    <carousel :items-to-show="3" :wrap-around="true">
      <slide v-for="category in categories" :key="category.id">
        <router-link
          class="category__container"
          :to="{ name: 'Recipe sirman', params: { id: category.id } }"
        >
          <category-card
            :title="category.title"
            :image="category.image"
            :id="category.id"
          ></category-card>
        </router-link>
      </slide>

      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
</template>

<script>
import axios from 'axios';
import CategoryCard from './CategoryCard';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'CategoriesSection',
  components: { CategoryCard, Carousel, Slide, Navigation },

  data() {
    return {
      categories: [],
    };
  },

  methods: {
    setCategories() {
      axios
        .get(this.api.categoriesRecipe + "?"  + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          res.data.forEach((item) => {
            this.categories.push({
              id: item.id,
              title: item.name,
              image: item.imgSrc,
            });
          });
        })
        .catch((err) => {
          console.log('Error: ' + err);
        });
    },
  },

  created() {
    this.setCategories();
  },
};
</script>

<style scoped>
h2 {
  margin: 0 0 20px;
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  color: var(--var-primary-color);
}

.categories {
  padding: 10% 0;
  max-width: 90%;
  margin: 0 auto;
}

.category__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.carousel__prev,
.carousel__next {
  background-color: #fff !important;
  color: #333 !important;
}

.carousel__prev {
  left: 0px !important;
}

.carousel__next {
  right: 0px !important;
}
</style>
