<template>
  <div class="news">
    <router-link :to="{ name: 'Bardender' }">
      <h2>{{ $gettext('Professione Bartender') }}</h2>
    </router-link>
    <h3>{{ $gettext('Notizie dal mondo Sirman') }}</h3>
    <div style="clear: both"></div>
    <div class="news__listing" v-if="_news.length > 0">
      <div class="news__list-item" v-for="news in _news" :key="news.id">
        <div class="news__list-inner">
          <router-link
            :to="{ name: 'Bartender Details', params: { id: news.id } }"
          >
            <div class="touch-gradient">
              <div class="image">
                <img :src="news.image" />
              </div>
              <div class="title" v-html="news.title"></div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BartenderSection',

  data() {
    return {
      _news: [],
    };
  },

  methods: {
    async addNewsList() {
      this.axios
        .get(this.api.bartenderListing + '?per_page=3&'  + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          res.data.forEach(async (item, index) => {
            const img = await this.getFeaturedImage(item.id);
            this._news.push({
              id: item.id,
              title: item.title.rendered,
              image: img,
            });
          });
        })
        .catch((err) => {
          console.log('Error: ' + err);
        });
    },
    async getFeaturedImage(id) {
      let response = await this.axios.get(this.api.getFeaturedImage, {
        params: { id: id },
      });
      return response.data.img;
    },
  },

  mounted: function () {
    this.addNewsList();
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
h2 {
  margin: 0;
  font-family: 'Barlow', sans-serif;
  color: var(--var-primary-color);
  text-decoration: none;
}
h3 {
  margin: 0 0 10px 0;
  text-transform: uppercase;
  font-size: 70%;
  color: #343434;
  /*margin: 40px 0 0;*/
}

.news {
  margin-bottom: 30px;
  width: 100%;
  float: left;
  clear: both;
  position: relative;
  /*border-radius: 16px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
   */
}

.news__listing {
  width: 100%;
  display: block;
  /* margin-top: -30px; */
  float: left;
  clear: both;
  box-shadow: none !important;
}
.news__list-item {
  width: 33%;
  max-width: 33%;
  padding: 0;
  text-align: left;
  float: left;
}

.news__list-inner {
  display: block;
  font-size: 1.25rem;
  padding: 5px;
  border-bottom: 1px solid #eee;
  font-weight: 600;
}

.news__list-item a {
  text-decoration: none;
}

.news__list-inner .title {
  font-size: 12px;
  color: black;
  height: 50px;
  overflow: hidden;
  text-transform: uppercase;
}

.news__list-item img {
  width: 100%;
  max-width: 100%;
}

.news__list-item p,
.news__list-item a {
  text-decoration: none;
  background: #333;
  background: -webkit-linear-gradient(to right, #333 0%, #333 100%);
  background: -moz-linear-gradient(to right, #333 0%, #333 100%);
  background: linear-gradient(to right, #333 0%, #333 100%);
  background-clip: text;
  /*-webkit-text-fill-color: transparent;*/
  transition: 0.1s;
}

.news__list-item:hover p,
.news__list-item:hover a {
  background: #4f04cf;
  background: -webkit-linear-gradient(to right, #4f04cf 0%, #cf19b1 100%);
  background: -moz-linear-gradient(to right, #4f04cf 0%, #cf19b1 100%);
  background: linear-gradient(to right, #4f04cf 0%, #cf19b1 100%);
  background-clip: text;
  /*-webkit-text-fill-color: transparent;*/
}

.news__entry-title {
  margin: 0;
}

.news__entry-image {
  width: 100%;
  max-height: 300px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.news__entry-image img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.news__entry-image:after {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(
    315deg,
    rgba(0, 119, 255, 0.4) 0%,
    rgba(255, 0, 62, 0.9) 100%
  );
  mix-blend-mode: multiply;
  transition: 0.3s;
  transform: translateZ(1px);
  backface-visibility: hidden;
  will-change: opacity;
  z-index: 1;
}

.news__entry-image h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.75rem;
  text-transform: uppercase;
  z-index: 999;
  letter-spacing: 4px;
  transition: 0.3s;
}

.news__entry-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  z-index: 1;
}
</style>
