const webSite = "https://cocktails-wp.sirman.com";
//const webSite = "https://pwa.studioverde.it";


const api = {
  machineData: webSite + "/wp-json/wp/v2/macchina/",
  machineListing: webSite + "/wp-json/wp/v2/macchina",
  getMediaUrl: webSite + "/wp-json/wp/v2/media/",
  getRecipeDetails: webSite + "/wp-json/wp/v2/ricetta/",
  getRecipeBookmark: webSite + "/wp-json/wp/v2/ricetta?mybook=1",
  getRecipeListing: webSite + "/wp-json/wp/v2/ricetta?type=0",
  getRecipeAuthor: webSite + "/wp-json/wp/v2/ricetta?author=",
  getRecipeListingUsers: webSite + "/wp-json/wp/v2/ricetta?type=1",
  newsListing: webSite + "/wp-json/wp/v2/news",
  newsDetails: webSite + "/wp-json/wp/v2/news/",
  pageListing: webSite + "/wp-json/wp/v2/pages",
  pageDetails: webSite + "/wp-json/wp/v2/pages/",
  categoriesRecipe: webSite + '/wp-json/wp/v2/categoria_ricetta',
  getFeaturedImage: webSite + '/wp-json/wp/v2/post/featured_image',

  //Bartender
  bartenderData: webSite + "/wp-json/wp/v2/bartender/",
  bartenderListing: webSite + "/wp-json/wp/v2/bartender",

  //Media
  sendMedia: webSite + "/wp-json/wp/v2/media",

  //AUTH
  login: webSite + "/wp-json/jwt-auth/v1/token", // ?username=michel&password=@kpQTgNOFV9JXieC*YlVpq)k"
  signup: webSite + "/wp-json/wp/v2/user/register",
  checkcode: webSite + "/wp-json/wp/v2/user/active",
  resendCode: webSite + "/wp-json/wp/v2/user/new_code",
  reset_step_1: webSite + "/wp-json/wp/v2/user/reset_password_step1",
  reset_step_2: webSite + "/wp-json/wp/v2/user/reset_password_step2",

  //USer
  getDataProfileOther: webSite + "/wp-json/wp/v2/user/details/",
  changePassword: webSite + "/wp-json/wp/v2/user/change_password",

  //Profile
  getMe: webSite + "/wp-json/wp/v2/user/me",

  //Bookmark
  bookmark: webSite + "/wp-json/wp/v2/user/bookmark",
  like: webSite + "/wp-json/wp/v2/user/like",
  follow: webSite + "/wp-json/wp/v2/user/follow",

  //Messaggio
  messaggio: webSite + "/wp-json/wp/v2/messaggio",
};

export default api;
