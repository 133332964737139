const authModule = {
  namespaced: true,
  actions: {},
  state: {
    loggedIn: false,
    token: '',
    tokenExpire: 0,
    id: 0,
    bio: 0,
    avatar: '',
    nicename: '',
    name: '',
    surname: '',
    bookmark: [],
    like: [],
    follow: [],
    first_name: '',
    last_name: '',
    language: '',
  },

  getters: {
    isLoggedIn(state) {
      return state.loggedIn;
    },
    isTokenValid(state) {
      const now = Math.round(Date.now() / 1000);
      return state.tokenExpire > now;
    },
    getToken(state) {
      return state.token;
    },
    getDataProfile(state) {
      return {
        id: state.id,
        bio: state.bio,
        avatar: state.avatar,
        first_name: state.first_name,
        last_name: state.last_name,
        name: state.name,
        nicename: state.name,
        bookmark: state.bookmark,
        like: state.like,
        follow: state.follow,
        logged: state.loggedIn,
        language: state.language
      };
    },
    getLanguageRest(state) {
      if (state.language == '' || state.language == null) {
        return "wpml_language=it";
      }
      return "wpml_language="+state.language;
    },
    getLanguage(state) {
      if (state.language == '' || state.language == null) {
        return "it";
      }
      return state.language;
    }
  },
  mutations: {
    setLoggedIn(state, data) {
      state.loggedIn = true;
      state.token = data.token;
      state.tokenExpire = data.tokenExpire;
    },
    setLogout(state) {
      state.loggedIn = false;
      state.token = '';
      state.tokenExpire = 0;
      state.like = { ricetta: [], user: [] };
      state.bookmark = { ricetta: [], user: [] };
      state.language = 'it';
    },
    setDataProfile(state, data) {
      state.nicename = data.name;
      state.bio = data.bio;
      state.id = data.id;
      state.avatar = data.avatar;
      state.bookmark = data.bookmark;
      state.like = data.like;
      state.follow = data.follow;
      state.first_name = data.first_name;
      state.last_name = data.last_name;
      state.language = data.language;
      console.log("setDataProfile",state);
    },

    addBookmark(state) {},
    setLanguage(state, lingua) {
      state.language = lingua;
    }
  },
};

export default authModule;
