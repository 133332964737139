import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/home/Home';

const routes = [
  {
    path: '/',
    name: 'Home2',
    component: Home,
  },
  {
    path: '/index.html',
    name: 'Home',
    component: Home,
  },
  {
    path: '/machine',
    name: 'Macchine sirman',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/machine/MachineListing.vue'),
  },
  {
    path: '/machine/:id',
    name: 'Machine Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/machine/MachineDetails.vue'),
  },
  {
    path: '/ricette',
    name: 'Recipe sirman',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/recipe/RecipeListing.vue'),
  },
  {
    path: '/ricette_utenti',
    name: 'Recipe sirman utenti',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/recipe/RecipeListing.vue'),
  },
  {
    path: '/ricette/nuova',
    name: 'Recipe new',
    component: () => import('../components/recipe/RecipeNew.vue'),
  },
  {
    path: '/ricette/stellaroux',
    name: 'StellaRoux',
    component: () => import('../components/recipe/RecipeListingStellaRoux'),
  },
  {
    path: '/ricette/:id',
    name: 'Recipe Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/recipe/RecipeDetails.vue'),
  },
  {
    path: '/ricette-stellaroux/:id',
    name: 'Recipe Details Stella Roux',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/recipe/RecipeDetailsStellaRoux.vue'),
  },
  {
    path: '/notizie',
    name: 'News Listing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/news/NewsListing.vue'),
  },
  {
    path: '/notizie/:id',
    name: 'News Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/news/NewsDetails.vue'),
  },
  {
    path: '/page',
    name: 'Page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/page/PageListing.vue'),
  },
  {
    path: '/page/:id',
    name: 'Page Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/page/PageDetails'),
  },
  {
    path: '/bartender',
    name: 'Bardender',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/bartender/BartenderListing'),
  },
  {
    path: '/bartender/:id',
    name: 'Bartender Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/bartender/BartenderDetails'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/auth/login'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../components/auth/signup'),
  },
  {
    path: '/signup_code',
    name: 'Signup check code',
    component: () => import('../components/auth/signup_code'),
  },
  {
    path: '/resend_code',
    name: 'resend code',
    component: () => import('../components/auth/resend_code'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../components/user/profile'),
  },
  {
    path: '/profile/cambiopassword',
    name: 'Cambio password',
    component: () => import('../components/user/changePassword'),
  },
  {
    path: '/lost_password_step_1',
    name: 'Lost password step 1',
    component: () => import('../components/auth/lost_password_step1'),
  },
  {
    path: '/lost_password_step_2',
    name: 'Lost password step 2',
    component: () => import('../components/auth/lost_password_step2'),
  },
  {
    path: '/utente/:id',
    name: 'Profile other',
    component: () => import('../components/user/profileOther'),
  },
  {
    path: '/myfeed',
    name: 'MyFeed',
    component: () => import('../components/myfeed/myfeed'),
  },
  {
    path: '/mybook',
    name: 'MyBook',
    component: () => import('../components/mybook/mybook'),
  },
  {
    path: '/profile/sendmessage',
    name: 'Profile sendmessage',
    component: () => import('../components/user/sendMessage'),
  },
  {
    path: '/profile/chage-data',
    name: 'Profile change data',
    component: () => import('../components/user/profileChange'),
  },
  //DA
  //DA NON USARE. Creato come ultimo blocco per i blocchi sopra quando vanno nel git che devono finire con la ,
  {
    path: '/last',
    name: 'last',
    component: Home, // Uno vale l'altro
  },
  /*
  {
    path: '/macchine',
    name: 'Macchine',
    component: () => import('../')
  }, */
  /* {
    path: '/news',
    name: 'News',
    component: () => import('../components/news/Listing.vue')
  },
  {
    path: '/news/:id',
    name: 'SingleNews',
    component: () => import('../components/news/SingleNews.vue')
  },

  {
    path: '/bartender',
    name: 'BartenderListing',
    component: () => import('../components/bartender/BartenderListing.vue')
  },
  {
    path: '/bartender/:id',
    name: 'BartenderSingle',
    component: () => import('../components/bartender/BartenderSingle')
  },

  {
    path: '/cocktail',
    name: 'CocktailListing',
    component: () => import('../components/cocktail/CocktailListing')
  },
  {
    path: '/bartender/:id',
    name: 'CocktailSingle',
    component: () => import('../components/cocktail/CocktailSingle')
  },

   */
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
